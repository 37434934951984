import React from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import MainContent from './components/MainContent'

function App() {
	return (
		<div className="App">
			<MainContent/>	
		</div>
	)
}

export default App
